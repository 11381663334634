<template>
  <div class="take-photo-wrapper">
    <div class="content-layout-fixer">
      <div class="content">
        <div class="left">
          <div class="img-box">
            <img
              src="@/assets/logos/philip-morris-logo.png"
              class="logo"
              alt="philip-morris-logo"
            />
          </div>
        </div>
        <div class="right">
          <VueText sizeLevel="12" weightLevel="3"
            >Görüntü Tanıma Teknolojisi ile Saha Çalışması</VueText
          >
          <ul class="info-list">
            <li class="info-list-item">
              Sisteme nasıl giriş yapacağım?
              <div class="info-list-content">
                Sistem bir uygulama üzerinden çalışmamaktadır. Sisteme telefonunuzda bulunan
                tarayıcı üzerinden (chrome kullanılması gerekmektedir) PMAktif.com adresine giriş
                yapabilirsiniz.
              </div>
            </li>
            <li class="info-list-item">
              Kullanıcı adı ve şifresini bilmiyorum, nereden ulaşabilirim?
              <div class="info-list-content">
                Barkodlu etiket uzerinde kullanıcı adı ve şifresini bulabilirsiniz. İlgili barkod
                etiketleri raf üzerinde bulunmaktadır.
              </div>
            </li>
            <li class="info-list-item">
              Mağazada bir veya bir kaç rafta barkod bulamadım.
              <div class="info-list-content">
                Barkod bazı mağazalarda satış ünitesi üzerinde bazı mağazalarda kasa hattında
                bulunabilir. Barkod’un yerini ilgili kasiyere de sorabilirsiniz. Bir mağazada 4’ten
                fazla satış ünitesi var ise maksimum 4 satış ünitesinde barkod bulunmaktadır. Sadece
                barkod bilgisi bulunan bu 4 ünitenin fotoğrafının çekilmesi yeterlidir.
              </div>
            </li>
            <li class="info-list-item">
              Mağazada bulunması gereken barkod yırtılmış/yıpranmış okunmuyor.
              <div class="info-list-content">
                Lütfen konu ile ilgili yöneticinize veya ziyarete gelen PM yetkilisine mağaza ismi
                raf numarası ile beraber bilgi veriniz.
              </div>
            </li>
            <li class="info-list-item">
              Barkod çekimi öncesi konum bilgilerine izin vermedim, fotoğraf çekemiyorum.
              <div class="info-list-content">
                Fotoğraf çekim işlemi için konum bilgilerine izin vermeniz gerekmektedir. İzin
                vermek için Ayarlar>Uygulamalar>Tarayıcı Uygulaması>İzinler>Konum Bilgisi İzin Ver
                şeklinde ilerleyebilirsiniz.
              </div>
            </li>
            <li class="info-list-item">
              Fotoğraf çekerken satış ünitesinin kapağını açmam gerekiyor mu?
              <div class="info-list-content">
                Fotoğrafların net yansımasız ve okunabilir olmasına özen gösterilmeli ve ünitelerin
                kapakları açık, karşıdan tüm ürünleri kapsayacak şekilde dik bir şekilde fotoğraf
                çekmelisiniz.
              </div>
            </li>
            <li class="info-list-item">
              Raf/satış ünitesi fotoğraflarını çektim ama gönderemiyorum.
              <div class="info-list-content">
                <p>
                  Gönderim işlemi yapmak için mağazada bulunan tanımlanmış tüm ünitelerin
                  fotoğraflarını çekmeniz gerekmektedir. Gönder butonu üzerinde kaç fotoğraf
                  eklemeniz gerektiği bilgisi bulunmaktadır.
                </p>
                <p>
                  Telefonunuzun operatör kapsama alanında olduğundan ve internet bağlantısının
                  çalışıyor olduğundan emin olunuz. Sayfayı yenileyerek ve telefonun hücresel
                  verisini kapatıp açarak tekrar deneyiniz. Problem devam ederse yöneticinize bilgi
                  veriniz.
                </p>
              </div>
            </li>
            <li class="info-list-item">
              Bulunduğum mağazada satış ünitesi büyük, tek fotoğrafta tüm ürünleri
              fotoğraflayamıyorum.
              <div class="info-list-content">
                İlgili satış ünitesine ait barkod’u okutarak ünitenin 1. yarısının fotoğrafını,
                sonrasında aynı barkodu okutarak aynı ünitenin 2. yarısının fotoğrafını
                çekebilirsiniz.
              </div>
            </li>
            <li class="info-list-item">
              Yanlış fotoğraf çektim ne yapmam lazım?
              <div class="info-list-content">
                İlgili fotoğrafın yanında bulunan “Değiştir” butonuna tıklayarak tekrardan fotoğraf
                çekebilirsiniz veya yanlış fotoğrafı “Sil” butonu ile silebilirsiniz.
              </div>
            </li>
            <li class="info-list-item">
              Bunların dışında bir problem yaşıyorum.
              <div class="info-list-content">
                Lütfen yöneticinize konu hakkında bilgi veriniz.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';

export default {
  name: 'Faq',
  components: { VueText },
  computed: {
    constants() {
      return {
        size: COMPONENT_CONSTANTS.COMPONENT_SIZES,
        icons: {
          waveHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.waveHome, color: '#ffffff' },
          arrowLeft: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.leftArrow, color: '#b4c2d3' },
          arrowRight: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.rightArrow, color: '#b4c2d3' },
          truck: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.truck, color: '#79838e' },
          wallet: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.wallet, color: '#79838e' },
          priceListHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.priceListHome, color: '#E5472D' },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 40px 20px;
  position: relative;
  background-color: #fff;
  display: flex;
}
.content-header {
  display: flex;
  align-items: center;
}
.background {
  height: 110px;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
}
.layout-default {
  /deep/ .layout-main-holder {
    top: -1px;
  }
}
.img-box {
  width: 150px;
  height: 150px;
  border-radius: 2px;
  border: solid 0.5px #b4c2d3;
  background-color: #ffffff;
  padding: 13px;
  flex-shrink: 0;
  margin-right: 30px;
  display: flex;
  align-items: center;
  .logo {
    max-width: 100%;
  }
}
.info-list {
  list-style: none;
  margin-top: 24px;
  &-item {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    color: #23303d;
    padding-left: 20px;
    margin-bottom: 22px;
    position: relative;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 7px 10px 29px 0;
      background-image: linear-gradient(315deg, #d20051, #ffa300);
      position: absolute;
      left: 0;
    }
  }
  &-content {
    margin-top: 5px;
    color: #79838e;
    p {
      margin-bottom: 5px;
    }
  }
  &-subitem {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #79838e;
    padding-left: 18px;
    margin-bottom: 18px;

    &:first-child {
      margin-top: 10px;
    }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 7px 10px 29px 0;
      background-image: linear-gradient(315deg, #d20051, #ffa300);
      position: absolute;
      left: 30px;
    }
  }
}
.take-photo {
  width: 180px !important;
  height: 54px !important;
  border-radius: 30px;
  margin-top: 50px;
}
</style>
